import React from 'react';

export default class Tag extends React.Component {
  render() {
    const tag = this.props.tag;
    // console.log(typeof this.props.onTagToggle)
    return (
      <div class={"badge badge-primary " + (this.props.active ? "active" : "inactive")} onClick={() => {this.props.onTagToggle(tag)}}>{tag}</div>
    )
  }
}
