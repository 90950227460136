// import fontawesome from "@fortawesome/fontawesome-free";
// import farCalendar from "@fortawesome/fontawesome-free-regular/faCalendarAlt";
// import farEnvelope from "@fortawesome/fontawesome-free-regular/faEnvelope";
// import fasArrowUp from "@fortawesome/fontawesome-free-solid/faArrowUp";
 
// fontawesome.library.add(farCalendar, farEnvelope, fasArrowUp);


import React from 'react';
import ReactDOM from 'react-dom';
import ProjectsModule from '../components/ProjectsModule.jsx';
import data_projects from '../json/projects.json'; //https://stackoverflow.com/questions/39686035/import-json-file-in-react

ReactDOM.render(<ProjectsModule projects={data_projects}/>, document.getElementById('component-projects'));