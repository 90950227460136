import React from 'react';
import FlipMove from 'react-flip-move'; // ES6

import Filters from './Filters.jsx';
import Tag from './Tag.jsx';
import Masonry from 'react-masonry-css';

export default class ProjectsModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters : []
    };
  }
  handleTagToggle = (tag) => {
    this.setState((previous_state, props) => {
      var _filters = previous_state.filters;
      const _index = _filters.indexOf(tag);
      
      if(_index >= 0)
        _filters.splice(_index, 1);
      else
        _filters.push(tag);

      return { filters : _filters };
    })
  }
  render() {
    return (
      <section>
        <Filters projects={this.props.projects} filters={this.state.filters} onTagToggle={this.handleTagToggle}/>
        <ProjectsGrid projects={this.props.projects} filters={this.state.filters}  onTagToggle={this.handleTagToggle}/>
      </section>
    )
  }
}

class ProjectsGrid extends React.Component {
  render() {
    const projects = [];
    this.props.projects.forEach((project) => {
      //S'il n'y a aucun filtrage en cours || que le projet a un tag en commun avec les filtres 
      if (this.props.filters.length == 0 || project.tags.filter((tag) => {return this.props.filters.includes(tag)}).length) {
        projects.push(<Project project={project} key={project.title} onTagToggle={this.props.onTagToggle}/>)
      }
      
    });

    return (

      <Masonry
        breakpointCols={{
          default: 3,
          990: 2,
          660: 1
        }}
        className="masonry-grid"
        columnClassName="masonry-grid_column">
          {projects}
      </Masonry>
    )
  }
}

class Project extends React.Component {
  render() {
    const tags = [],
          project = this.props.project;

    project.tags.forEach((tag) => {
      tags.push(<Tag tag={tag} key={tag} onTagToggle={this.props.onTagToggle}/>)
    });

    return (
      <div class="card mb-6 box-shadow">
        <img class="card-img-top" src={project.image_url}/>
        <div class="card-body">
          <h2 class="card-title">{project.title}</h2>
          <p class="card-text">{project.description}</p>
          {!!project.site_url && <a class="btn btn-success" target={project.title} href={project.site_url}><i class="far fa-eye mr-2"></i><span>Consulter le site</span></a>}
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div class="tags mb-2">
              {tags}
            </div>
            <small class="text-muted">Réalisation : {project.date_realisation}</small>
          </div>
        </div>
      </div>
    )
  }
}