import React from 'react';
import Tag from './Tag.jsx';

export default class Filters extends React.Component {
  constructor(props){
    super(props);
    this.availableTags = this.extractTagsFromProjects(props.projects);
  }

  availableTags = []

  extractTagsFromProjects(projects) {
    //https://stackoverflow.com/a/41364433/1437016
    var _set = new Set();
    projects.forEach(project => project.tags.forEach(tag => _set.add(tag)));
    return [..._set];
  }

  render() {
    var _tags = this.availableTags.map((tag) => {return <Tag tag={tag} key={tag} active={this.props.filters.length == 0 || this.props.filters.includes(tag)} onTagToggle={this.props.onTagToggle}/>});

    return (
      <div class="tags filters">
        <span>Catégories : </span>
        {_tags}
      </div>
    );
  }
}